import type { InputHTMLAttributes } from "react";
import { motion } from "motion/react";
import { twMerge } from "tailwind-merge";
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	error?: string;
}

export default function Input({ label, error, className = "", id, ...props }: InputProps) {
	const baseStyles =
		"appearance-none block w-full px-3 py-2 border rounded-lg transition-all placeholder-gray-400 text-primary focus:outline-none focus:ring-2 focus:ring-t-blue/80";

	return (
		<div className="">
			<label htmlFor={id} className="text-primary block text-sm font-medium text-black/70">
				{label}
			</label>
			<input id={id} className={twMerge(baseStyles, className)} {...props} />
			{error && (
				<motion.p initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} className="text-sm text-red-600">
					{error}
				</motion.p>
			)}
		</div>
	);
}
