import type { ButtonHTMLAttributes, ReactNode } from "react";
import { CgSpinner } from "react-icons/cg";
import { motion, type MotionProps } from "motion/react";
import { twMerge } from "tailwind-merge";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: "primary" | "secondary" | "outline";
	isLoading?: boolean;
	children?: ReactNode;
}

export default function Button({ children, className = "", variant = "primary", isLoading, disabled, ...props }: ButtonProps) {
	const baseStyles = "relative w-full flex justify-center py-1.5 px-3 text-sm font-medium rounded transition-colors focus:outline-none";
	const variantStyles = {
		primary: "text-white bg-t-blue hover:bg-t-blue/80",
		secondary: "text-primary bg-light hover:bg-light/80",
		outline: "text-primary bg-white border border-gray-300 hover:bg-gray-100",
	}[variant];

	const stateStyles = isLoading || disabled ? "opacity-50 cursor-not-allowed" : "";

	return (
		<motion.button
			whileTap={!isLoading && !disabled ? { scale: 0.98 } : {}}
			className={twMerge(baseStyles, variantStyles, stateStyles, className)}
			disabled={isLoading || disabled}
			{...(props as MotionProps)}
		>
			{isLoading ? <CgSpinner className="h-5 w-5 animate-spin" /> : children}
		</motion.button>
	);
}
