"use client";

import { useState } from "react";
import Input from "@/app/ui/primitives/Input";
import Button from "@/app/ui/primitives/Button";
import Image from "next/image";
import { login } from "@/app/lib/users";
import { motion, AnimatePresence } from "motion/react";

export default function LoginForm() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const requestLogin = async () => {
		setLoading(true);
		setError("");
		if (username === "" || password === "") {
			setError("Please enter an email address and password");
			setLoading(false);
			return;
		}
		const res = await login(username, password);
		if (!res) {
			setError("Invalid email address or password");
			setLoading(false);
			return;
		}
	};
	return (
		<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
			<div className="w-full max-w-md space-y-8">
				<motion.div initial={{ y: -20 }} animate={{ y: 0 }} transition={{ delay: 0.2 }}>
					<Image src="/logo.png" alt="Logo" height={48} width={200} className="mx-auto" />
					<p className="text-secondary mt-4 text-center text-xl font-semibold">Thompson Training NVQ Portfolio Manager</p>
				</motion.div>

				<div className="relative h-[200px]">
					<AnimatePresence mode="wait">
						<motion.div
							key="form"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{
								duration: 0.3,
								ease: "easeInOut",
							}}
							className="absolute left-0 right-0 top-0"
						>
							<div className="mb-6 space-y-4">
								<Input
									label="Email Address"
									type="email"
									autoComplete="email"
									placeholder="Enter your email address"
									disabled={loading}
									value={username}
									onChange={(e) => setUsername(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === "Enter") requestLogin();
									}}
								/>

								<Input
									label="Password"
									type="password"
									autoComplete="current-password"
									placeholder="Enter your password"
									error={error}
									disabled={loading}
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === "Enter") requestLogin();
									}}
								/>
							</div>

							<Button isLoading={loading} onClick={requestLogin} className="py-2.5">
								Sign in
							</Button>
						</motion.div>
					</AnimatePresence>
				</div>
			</div>
		</motion.div>
	);
}
